import axios from 'axios';

export default class OrderExternalService {
  static async getOrderConfig(orderId, secretKey) {
    const response = await axios.get(`/api/v2/orders/config/external/${orderId}/${secretKey}`);
    return response.data;
  }

  static async getOrderById(orderId, secretKey) {
    const response = await axios.get(`/api/v2/orders/external/${orderId}/${secretKey}`);
    return response.data;
  }

  static async editOrder(jsonData, csrfToken) {
    const response = await axios.put('/api/v2/orders/external', jsonData, {
      headers: {
        'X-CSRFToken': csrfToken,
      },
    });
    return response.data;
  }
}
