import axios from 'axios';

export default class OrderService {
  static async getOrderConfig() {
    const response = await axios.get('/api/v2/orders/config');
    return response.data;
  }

  static async getOrderTypesConfig() {
    const response = await axios.get('/api/v2/orders/config/types');
    return response.data;
  }

  static async getOrderSelectFieldsConfig(withInactiveSelectFieldValues = false) {
    const response = await axios.get('/api/v2/orders/config/types/select-fields', {
      params: {
        ...(withInactiveSelectFieldValues ? { with_inactive_select_field_values: '1' } : {}),
      },
      paramsSerializer: { indexes: null },
    });
    return response.data;
  }

  static async getOrderSelectFieldsConfigForExistingOrder(orderId) {
    const response = await axios.get(`/api/v2/orders/config/types/select-fields/orders/${orderId}`);
    return response.data;
  }

  static async updateOrderSelectFieldConfig(selectField, csrfToken) {
    const response = await axios.put('/api/v2/orders/config/select-fields', selectField, {
      headers: {
        'X-CSRFToken': csrfToken,
      },
    });
    return response.data;
  }

  static async getOrderById(order_id) {
    const response = await axios.get(`/api/v2/orders/${order_id}`);
    return response.data;
  }

  static async listOrders(params) {
    const response = await axios.get('/api/v2/orders', {
      params: params,
      paramsSerializer: { indexes: null },
    });
    return response.data;
  }

  static async createNewOrder(jsonData, csrfToken) {
    const response = await axios.post('/api/v2/orders', jsonData, {
      headers: {
        'X-CSRFToken': csrfToken,
      },
    });
    return response.data;
  }

  static async editOrder(jsonData, csrfToken) {
    const response = await axios.put('/api/v2/orders', jsonData, {
      headers: {
        'X-CSRFToken': csrfToken,
      },
    });
    return response.data;
  }

  static async getOrderInvoiceList(params) {
    const response = await axios.get('/api/v2/orders/invoices', {
      params: params,
      paramsSerializer: { indexes: null },
    });
    return response.data.orders;
  }

  static async patchOrder(jsonData, csrfToken) {
    const response = await axios.patch(`/api/v2/orders`, jsonData, {
      headers: {
        'X-CSRFToken': csrfToken,
      },
    });
    return response.data;
  }

  static async generateOrdersHistoryReportXlsx(jsonData, csrfToken) {
    const response = await axios.post('/api/v2/orders/reports/history/xlsx', jsonData, {
      headers: {
        'X-CSRFToken': csrfToken,
      },
      responseType: 'blob',
    });

    return {
      data: response.data,
      filename: response.headers['content-disposition'].match(/filename="(.+)"/)[1],
    };
  }
}
