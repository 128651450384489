export const CURRENCY = 'EUR';
export const JSON_STRINGIFY_SPACE = 4;

export const ITEMS_PER_PAGE_OPTIONS = [25, 50, 100, 250, 500, 1000];
export const ITEMS_PER_PAGE_DEFAULT = 50;

export const FLASH_CLOSE = 12 * 1000; // 12 seconds

export const SIDEBAR_COLLAPSE_WIDTH = 992;
export const SIDEBAR_HIDE_WIDTH = 720;

export const ORDERS_MAIN_PAGE_URL = '/orders';
export const ORDER_DEFAULT_STATUS_GROUPS = [1];
export const ORDER_TABLE_CONFIG_LOCAL_STORAGE_KEY = 'orderTableConfig?v=6';
export const ORDER_NEW_LAST_SELECTED_ORDER_TYPE_LOCAL_STORAGE_KEY = 'newOrderLastSelectedOrderType?v=1';
export const ORDER_MATERIAL_FIELDS = ['print_material', 'print_laminate'];

export const CLIENTS_MAIN_PAGE_URL = '/clients';
export const CLIENT_TYPES = [
  { text: 'Company', value: 'company' },
  { text: 'Person', value: 'person' },
];
export const DEFAULT_SELECTED_CLIENT_TYPES = ['company', 'person'];
export const CLIENT_SOURCES = [
  { text: 'Internal', value: 'internal' },
  { text: 'Scoro', value: 'crm' },
];
export const DEFAULT_SELECTED_CLIENT_SOURCES = ['internal', 'crm'];
