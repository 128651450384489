import _ from 'lodash';
import axios from 'axios';

export default class ClientService {
  static async getClientById({ clientId }) {
    const response = await axios.get(`/api/v2/clients/${clientId}`);
    return ClientService.deserializeToClientObject(response.data);
  }

  static async listClients({
    clientTypes,
    clientSources,
    clientNamePattern,
    search,
    sortBy,
    sortDirections,
    page,
    limit,
  }) {
    const response = await axios.get('/api/v2/clients', {
      params: {
        client_types: clientTypes,
        client_sources: clientSources,
        client_name_pattern: clientNamePattern,
        search,
        sort_by: sortBy ? sortBy.map(_.snakeCase) : null,
        sort_directions: sortDirections,
        page,
        limit,
      },
      paramsSerializer: { indexes: null },
    });

    return {
      clients: response.data.clients.map(ClientService.deserializeToClientObject),
      pagination: response.data.pagination,
    };
  }

  static async createClient({ data, csrfToken }) {
    const response = await axios.post('/api/v2/clients', data, {
      headers: {
        'X-CSRFToken': csrfToken,
      },
    });
    return ClientService.deserializeToClientObject(response.data);
  }

  static async updateClient({ data, csrfToken }) {
    const response = await axios.put('/api/v2/clients', data, {
      headers: {
        'X-CSRFToken': csrfToken,
      },
    });
    return ClientService.deserializeToClientObject(response.data);
  }

  static async deleteClient({ clientId, csrfToken }) {
    await axios.delete(`/api/v2/clients/${clientId}`, {
      headers: {
        'X-CSRFToken': csrfToken,
      },
    });
  }

  static deserializeToClientObject(client) {
    return {
      id: client.id,
      crmId: client.crm_id,
      name: client.name,
      clientType: client.client_type,
      invoiceEmail: client.invoice_email,
      contacts: client.contacts.map((contact) => {
        return {
          email: contact.email,
          isPrimary: contact.is_primary,
        };
      }),
      sendClientEmails: client.send_client_emails,
      createdAt: new Date(client.created_at),
      isDeleted: client.is_deleted,
    };
  }
}
