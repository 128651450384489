import Vue from 'vue';
import _ from 'lodash';

import { ITEMS_PER_PAGE_DEFAULT, ITEMS_PER_PAGE_OPTIONS } from '../../config';
import { dataTableLoadData } from '../../helpers/functions';
import { page } from '../../helpers/page';
import { utcDateTimeToLocalString } from '../../helpers/dates';
import OrderService from '../../services/OrderService';
import SecurityService from '../../services/SecurityService';
import UserService from '../../services/UserService';
import Vuetify from '../../helpers/vuetify';

page('/orders/invoices', function () {
  const app = new Vue({
    vuetify: Vuetify,
    delimiters: ['[[', ']]'],

    data() {
      return {
        page: 1,
        pageCount: 0,
        itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
        footerProps: {
          itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
        },
        filtersLoading: '',
        search: '',
        loading: false,
        error: false,
        snack: false,
        snackColor: '',
        snackText: '',
        snackTimeout: 4000,
        // Managers select
        managersSelectItems: [],
        selectedManagers: [],
        // Order invoice status select
        orderInvoiceStatusSelectItems: [
          { value: '1', text: 'Set' },
          { value: '2', text: 'Not Set' },
        ],
        selectedOrderInvoiceStatuses: ['2'],
        // Update dialog
        savedEditDialogValue: '',
        headers: [
          {
            key: 'id',
            text: 'Order #',
            sortable: true,
            value: 'id',
            groupable: false,
            displayed: true,
          },
          {
            key: 'order_type',
            text: 'Order type',
            sortable: true,
            value: 'order_type.name',
            groupable: true,
            displayed: true,
          },
          {
            key: 'status',
            text: 'Status',
            sortable: true,
            value: 'status.name',
            groupable: true,
            displayed: true,
          },
          {
            key: 'client',
            text: 'Client',
            sortable: true,
            value: 'client.name',
            groupable: true,
            displayed: true,
          },
          {
            key: 'manager',
            text: 'Manager',
            sortable: true,
            value: 'manager.name',
            groupable: true,
            displayed: true,
          },
          {
            key: 'title',
            text: 'Order Title',
            sortable: true,
            value: 'title',
            groupable: false,
            displayed: true,
          },
          {
            key: 'price',
            text: 'Price',
            sortable: true,
            value: 'price',
            groupable: false,
            displayed: true,
          },
          {
            key: 'invoice_number',
            text: 'Invoice number',
            sortable: true,
            value: 'invoice_number',
            groupable: false,
            displayed: true,
          },
          {
            key: 'created_at',
            text: 'Created at',
            sortable: true,
            value: 'created_at',
            groupable: false,
            date: true,
            displayed: true,
          },
          {
            key: 'invoice_number_updated_at',
            text: 'Invoice N. updated',
            sortable: true,
            value: 'invoice_number_updated_at',
            groupable: false,
            displayed: true,
          },
        ],
        orders: [],
      };
    },

    computed: {
      isRefreshDisabled() {
        if (this.loading || this.isFiltersLoading) {
          return true;
        }
        return false;
      },
      isFiltersLoading() {
        return this.filtersLoading;
      },
      isDataTableLoading() {
        return this.loading;
      },
      allManagersSelected() {
        return this.selectedManagers.length === this.managersSelectItems.length;
      },
      notAllManagersSelected() {
        return this.selectedManagers.length > 0 && !this.allManagersSelected;
      },
      selectManagersIcon() {
        if (this.allManagersSelected) return 'mdi-close-box';
        if (this.notAllManagersSelected) return 'mdi-minus-box';
        return 'mdi-checkbox-blank-outline';
      },
    },

    async created() {
      this.csrfToken = await SecurityService.getCsrfToken();

      await this.loadFilters();
      // Get order+invoice list
      await this.getOrdersInvoicesList();
      // Watches
      this.$watch('selectedManagers', this.getOrdersInvoicesList);
      this.$watch('selectedOrderInvoiceStatuses', this.getOrdersInvoicesList);
    },

    methods: {
      utcDateTimeToLocalString,

      toggleSelectManagers() {
        this.$nextTick(() => {
          if (this.allManagersSelected) {
            this.selectedManagers = [];
          } else {
            this.selectedManagers = this.managersSelectItems.slice().map((m) => m.value);
          }
        });
      },

      updateItemsPerPage(val) {
        this.itemsPerPage = val;
      },

      getButtonColor(item, fieldKey, fieldName, defaultColor) {
        if (!this.isValidString(item[fieldKey])) {
          return 'red';
        }
        if (item[fieldKey]) {
          if (item[`updatedInThisSession${fieldName}`]) {
            return 'green';
          }
          return defaultColor;
        }
        return 'orange ';
      },
      async loadFilters() {
        try {
          this.filtersLoading = true;
          const managersList = await this.getManagersView();
          this.managersSelectItems = managersList.map((user) => {
            return { value: user.id, text: user.name };
          });

          const currentUser = await UserService.getCurrentUser();
          this.selectedManagers = [currentUser.id];
        } catch (err) {
          console.error(err);
        } finally {
          this.filtersLoading = false;
        }
      },

      async getManagersView() {
        try {
          return (await OrderService.getOrderConfig()).managers;
        } catch (err) {
          console.error(err);
        }
      },

      async getOrdersInvoicesList() {
        const boundDataTableLoadData = dataTableLoadData.bind(this);
        await boundDataTableLoadData('orders', () =>
          OrderService.getOrderInvoiceList({
            managers: this.selectedManagers,
            invoice_number: this.selectedOrderInvoiceStatuses,
          }),
        );
      },

      // Validators
      validateStringOnInput(v) {
        return this.isValidString(v) || 'Input is too long!';
      },

      isValidString(v) {
        if (!v) return true;
        return v.length <= 32;
      },

      // Edit dialog
      isFieldLoading(item, fieldName) {
        return item[`isLoading${fieldName}`] || false;
      },

      async saveEditDialog(item, fieldKey, fieldName) {
        // Do not save if no changes
        if (item[fieldKey] === this.savedEditDialogValue) {
          return;
        }
        // Do not save empty string if filling invoice number for the first time
        if (!item[fieldKey] && !this.savedEditDialogValue.trim()) {
          return;
        }
        if (!this.isValidString(this.savedEditDialogValue)) {
          this.cancelEditDialog();
          return;
        }
        try {
          item[`isLoading${fieldName}`] = true;
          const response = await this.saveInvoice(item, fieldKey);
          item[fieldKey] = response[fieldKey];
          item[`updatedInThisSession${fieldName}`] = true;
          this.snackSuccess(item, `${fieldName} saved for order #${item.id}`);
        } catch (err) {
          console.error(err);
          this.snackFailed(item, `Failed to save ${fieldName} for Order #${item.id}`);
        } finally {
          item[`isLoading${fieldName}`] = false;
        }
      },

      cancelEditDialog() {
        this.savedEditDialogValue = '';
        this.snackClose();
      },

      openEditDialog(item, fieldKey) {
        this.savedEditDialogValue = item[fieldKey];
        this.snackClose();
      },

      // Snack
      snackSuccess(item, text) {
        this.snackColor = 'success';
        this.snackText = text;
        this.snackTimeout = 2000;
        this.snack = true;
      },

      snackFailed(item, text) {
        this.snackColor = 'red accent-2';
        this.snackText = text;
        this.snackTimeout = 15000;
        this.snack = true;
      },

      snackClose() {
        this.snack = false;
      },

      // Post to backend
      async saveInvoice(item, fieldKey) {
        return await OrderService.patchOrder(
          {
            id: item.id,
            order_type: item.order_type.id,
            [fieldKey]: this.savedEditDialogValue || null,
          },
          this.csrfToken,
        );
      },
    },
  });

  app.$mount('#app');
});
