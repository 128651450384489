import Vue from 'vue';
import _ from 'lodash';

import { ITEMS_PER_PAGE_OPTIONS } from '../../config';
import { dataTableLoadData } from '../../helpers/functions';
import { page } from '../../helpers/page';
import Flash from '../../helpers/Flash';
import OrderService from '../../services/OrderService';
import PricesService from '../../services/PricesService';
import Vuetify from '../../helpers/vuetify';

page('/prices/list', function () {
  const app = new Vue({
    vuetify: Vuetify,
    delimiters: ['[[', ']]'],
    data() {
      return {
        // Data table
        page: 1,
        pageCount: 0,
        itemsPerPage: -1,
        footerProps: {
          itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
        },
        search: '',
        loading: false,
        error: false,
        // Filters
        filtersLoading: false,
        listOrderTypes: [],
        selectedOrderType: '',
        printCount: null,
        twoSidePrint: false,
        selectedClient: '',
        listClients: [],
        // Price list
        headers: [
          {
            text: 'Material Type',
            sortable: true,
            value: 'material_type_name',
          },
          {
            text: 'Material Name',
            sortable: true,
            value: 'material_name',
          },
          {
            text: 'Material Supplier',
            sortable: true,
            value: 'material_supplier',
          },
          {
            text: 'Min Order Price',
            sortable: true,
            value: 'min_order_price',
          },
          {
            text: 'Material Price/Unit',
            sortable: true,
            value: 'material_price',
          },
          {
            text: 'Printing Price/Unit',
            sortable: true,
            value: 'printing_price',
          },
          {
            text: 'Total Line Item Price',
            sortable: true,
            value: 'total_price',
          },
          {
            text: 'Unit',
            sortable: true,
            value: 'material_unit',
          },
          {
            text: 'Price Calculation Strategy',
            sortable: true,
            value: 'printing_price_calculation_strategy',
          },
        ],
        priceList: [],
      };
    },
    computed: {
      isRefreshDisabled() {
        if (this.loading || !this.selectedOrderType) {
          return true;
        }
        return false;
      },
      isFiltersLoading() {
        return this.filtersLoading;
      },
      isDataTableLoading() {
        return this.loading;
      },
      isOptionalFiltersDisabled() {
        return !this.selectedOrderType || this.isDataTableLoading;
      },
    },
    async created() {
      await this.getFiltersConfig();
      this.$watch('selectedOrderType', this.getPriceList);
      this.$watch('selectedClient', this.getPriceList);
    },
    methods: {
      async getFiltersConfig() {
        try {
          this.filtersLoading = true;
          const orderTypes = (await OrderService.getOrderTypesConfig()).order_types;
          const clients = await PricesService.getAll();
          this.listOrderTypes = orderTypes.map((orderType) => {
            return { value: orderType.code, text: orderType.name };
          });
          this.listClients = clients.map((client) => {
            return { value: client.id, text: client.name };
          });
        } catch (err) {
          console.error(err);
          const flash = new Flash();
          flash.flash('Filter configuration loading has failed', 'error');
        } finally {
          this.filtersLoading = false;
        }
      },
      async getPriceList() {
        if (!this.selectedOrderType) {
          return;
        }
        const boundDataTableLoadData = dataTableLoadData.bind(this);
        const queryParams = {
          orderTypeCode: this.selectedOrderType,
        };
        if (this.printCount) {
          queryParams.printCount = this.printCount;
        }
        if (this.twoSidePrint) {
          queryParams.twoSidePrint = this.twoSidePrint;
        }
        if (this.selectedClient) {
          queryParams.clientId = this.selectedClient;
        }
        await boundDataTableLoadData('priceList', () => PricesService.getPriceList(queryParams));
      },
    },
  });
  app.$mount('#app');
});
