import Vue from 'vue';

import { ITEMS_PER_PAGE_DEFAULT, ITEMS_PER_PAGE_OPTIONS } from '../../config';
import { dataTableLoadData } from '../../helpers/functions';
import { page } from '../../helpers/page';
import { utcDateTimeToLocalString } from '../../helpers/dates';
import UserService from '../../services/UserService';
import Vuetify from '../../helpers/vuetify';

page('/users', function () {
  const app = new Vue({
    vuetify: Vuetify,
    delimiters: ['[[', ']]'],

    data() {
      return {
        page: 1,
        pageCount: 0,
        itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
        footerProps: {
          itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
        },
        search: '',
        loading: false,
        error: false,
        headers: [
          {
            text: 'Email',
            sortable: true,
            value: 'email',
          },
          {
            text: 'Name',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Role',
            sortable: true,
            value: 'role',
          },
          {
            text: 'Active',
            sortable: true,
            value: 'is_active',
          },
          {
            text: 'Hidden',
            sortable: true,
            value: 'is_hidden',
          },
          {
            text: 'Password Expired',
            sortable: true,
            value: 'is_password_expired',
          },
          {
            text: 'Blocked Until',
            sortable: true,
            value: 'blocked_until',
          },
          {
            text: 'Last Seen At',
            sortable: true,
            value: 'last_seen_at',
          },
          {
            text: 'Actions',
            value: 'id',
          },
        ],
        users: [],
      };
    },

    async created() {
      await this.listUsers();
    },

    methods: {
      utcDateTimeToLocalString,

      async listUsers() {
        const boundDataTableLoadData = dataTableLoadData.bind(this);
        await boundDataTableLoadData('users', UserService.listUsers);
      },
    },
  });

  app.$mount('#app');
});
