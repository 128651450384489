import axios from 'axios';

export default class PricesService {
  /**
   *
   * @param {Object} params API GET params
   * @returns
   */
  static async getPriceList(params = {}) {
    const response = await axios.get('/api/v1/prices/list', {
      params: params,
    });
    return response.data;
  }

  /**
   *
   * @param {string} id Client ID
   */
  static async get(id) {
    const response = await axios.get(`/api/v1/prices/clients/${id}`);
    return response.data;
  }

  /**
   *
   * @returns Array of all clients
   */
  static async getAll() {
    const response = await axios.get('/api/v1/prices/clients');
    return response.data;
  }

  /**
   *
   * @param {FormData} formData
   * @returns
   */
  static async newClient(formData) {
    const response = await axios.post(`/api/v1/prices/clients`, formData);
    return response.data;
  }

  /**
   *
   * @param {FormData} formData
   * @returns
   */
  static async editClient(formData) {
    const response = await axios.put(`/api/v1/prices/clients`, formData);
    return response.data;
  }

  /**
   *
   * @param {FormData} formData
   * @returns
   */
  static async deleteClient(formData) {
    const response = await axios.post(`/api/v1/prices/clients/delete`, formData);
    return response.data;
  }

  /**
   *
   * @param {FormData} formData
   * @returns
   */
  static async newFixedMaterialPrice(formData) {
    const response = await axios.post(`/api/v1/prices/fixed-material-price`, formData);
    return response.data;
  }

  /**
   *
   * @param {FormData} formData
   * @returns
   */
  static async editFixedMaterialPrice(formData) {
    const response = await axios.put(`/api/v1/prices/fixed-material-price`, formData);
    return response.data;
  }

  /**
   *
   * @param {FormData} formData
   * @returns
   */
  static async deleteFixedMaterialPrice(formData) {
    const response = await axios.post(`/api/v1/prices/fixed-material-price/delete`, formData);
    return response.data;
  }
}
