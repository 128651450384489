<template>
  <div>
    <v-toolbar class="grey lighten-3">
      <v-toolbar-title>Clients</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" href="/clients/new">New Client</v-btn>
    </v-toolbar>

    <v-container fluid>
      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn class="mb-3" small @click="queryClientsList">Refresh</v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="selectedClientTypes"
            :items="tableConfig.clientTypes"
            label="Client Type"
            multiple
            clearable
            small-chips
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="selectedClientSources"
            :items="tableConfig.clientSources"
            label="Client Source"
            multiple
            clearable
            small-chips
          >
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            label="Search"
            single-line
            hide-details
            clearable
            @keyup.enter="queryClientsListWithReturnToFirstPage"
          >
            <template #append-outer>
              <v-btn icon @click="queryClientsListWithReturnToFirstPage">
                <v-icon>search</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      class="table-striped"
      :headers="headers"
      :items="clients"
      :loading="loading"
      :search="search"
      :footer-props="footerProps"
      :options.sync="options"
      :server-items-length="serverItemsLength"
      item-key="id"
      show-group-by
      multi-sort
    >
      <template v-if="error" #body>
        <tbody>
          <tr class="text-center">
            <td colspan="100%">Table loading has failed. Please refresh the page and try again.</td>
          </tr>
        </tbody>
      </template>

      <template #[`item.crmId`]="{ item }">
        <td>
          <a class="color-inherit" :href="getScoroCrmClientLink(item)" target="_blank">
            {{ item.crmId }}
          </a>
        </td>
      </template>

      <template #[`item.sendClientEmails`]="{ item }">
        <td>
          <div class="text-center">
            <v-icon v-if="item.sendClientEmails">checkmark</v-icon>
          </div>
        </td>
      </template>

      <template #[`item.clientType`]="{ item }">
        <td>{{ item.clientType.charAt(0).toUpperCase() + item.clientType.slice(1) }}</td>
      </template>

      <template #[`item.createdAt`]="{ item }">
        <td>{{ item.createdAt ? utcDateTimeToLocalString(item.createdAt) : null }}</td>
      </template>

      <template #[`item.updatedAt`]="{ item }">
        <td>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn class="mr-1" v-bind="attrs" icon :href="`/clients/edit/${item.id}`" v-on="on">
                <v-icon>edit</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn v-if="!item.crmId" v-bind="attrs" icon v-on="on" @click="deleteClient(item)">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </td>
      </template>

      <template #[`footer.prepend`]>
        <div class="d-flex flex-column m-4">
          <v-text-field
            class="shrink"
            :value="options.page"
            label="Go to page"
            type="number"
            hide-details
            dense
            @keyup.enter="goToPage($event.target.value)"
          >
          </v-text-field>

          <div class="text-caption mt-4">
            <span class="font-weight-bold">Pro Tip:</span> Navigate between pages using Alt + Arrow Left/Right.
          </div>
        </div>
      </template>
    </v-data-table>

    <v-row>
      <v-col cols="12">
        <v-pagination v-model="options.page" :length="pageCount" :disabled="loading" total-visible="25"> </v-pagination>
      </v-col>
    </v-row>

    <scroll-to-top-fab></scroll-to-top-fab>
  </div>
</template>

<script>
import { useDebounceFn } from '@vueuse/core';

import {
  CLIENT_SOURCES,
  CLIENT_TYPES,
  DEFAULT_SELECTED_CLIENT_SOURCES,
  DEFAULT_SELECTED_CLIENT_TYPES,
  ITEMS_PER_PAGE_DEFAULT,
  ITEMS_PER_PAGE_OPTIONS,
} from '../config';
import { dataTableLoadData } from '../helpers/functions';
import { getScoroCrmClientLink } from '../helpers/scoroCrm';
import { utcDateTimeToLocalString } from '../helpers/dates';
import ClientService from '../services/ClientService';
import Flash from '../helpers/Flash';
import ScrollToTopFab from './ScrollToTopFab.vue';
import SecurityService from '../services/SecurityService';

export default {
  components: {
    ScrollToTopFab,
  },

  data() {
    return {
      pageCount: 0,
      serverItemsLength: 0,
      options: {
        page: 1,
        itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
      },

      csrfToken: null,

      footerProps: {
        itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
        showFirstLastPage: true,
        showCurrentPage: true,
      },

      search: '',

      loading: false,
      error: false,

      tableConfig: {
        clientTypes: CLIENT_TYPES,
        clientSources: CLIENT_SOURCES,
      },

      selectedClientTypes: DEFAULT_SELECTED_CLIENT_TYPES,
      selectedClientSources: DEFAULT_SELECTED_CLIENT_SOURCES,

      clients: [],

      headers: [
        {
          text: 'Client #',
          sortable: true,
          groupable: false,
          value: 'id',
        },
        {
          text: 'Name',
          sortable: true,
          groupable: false,
          value: 'name',
        },
        {
          text: 'Type',
          sortable: true,
          groupable: true,
          value: 'clientType',
        },
        {
          text: 'Scoro Client ID',
          sortable: true,
          groupable: false,
          value: 'crmId',
        },
        {
          text: 'Send Client Emails',
          sortable: true,
          groupable: true,
          value: 'sendClientEmails',
        },
        {
          text: 'Created At',
          sortable: true,
          groupable: false,
          value: 'createdAt',
        },
        {
          text: 'Actions',
          sortable: false,
          groupable: false,
          value: 'updatedAt',
        },
      ],
    };
  },

  watch: {
    options: {
      handler() {
        this.queryClientsList();
      },
      deep: true,
    },

    search: useDebounceFn(
      function () {
        this.queryClientsListWithReturnToFirstPage();
      },
      600,
      { maxWait: 1200 },
    ),

    selectedClientTypes() {
      this.queryClientsListWithReturnToFirstPage();
    },
    selectedClientSources() {
      this.queryClientsListWithReturnToFirstPage();
    },
  },

  async mounted() {
    await Promise.all([this.getCsrfToken(), this.queryClientsList()]);

    // Keyboard shortcuts
    window.addEventListener('keydown', this.navigatePaginationKeyboardShortcuts);
  },

  methods: {
    getScoroCrmClientLink,

    utcDateTimeToLocalString,

    async queryClientsListWithReturnToFirstPage() {
      if (this.options.page !== 1) {
        this.goToPage(1);
      } else {
        await this.queryClientsList();
      }
    },

    async queryClientsList() {
      const { groupBy, groupDesc, sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.clients = [];

      const boundDataTableLoadData = dataTableLoadData.bind(this);
      await boundDataTableLoadData(
        'clients',
        () => {
          return ClientService.listClients({
            clientTypes: this.selectedClientTypes || null,
            clientSources: this.selectedClientSources || null,
            search: this.search || null,
            sortBy: groupBy.concat(sortBy) || null,
            sortDirections: groupDesc.concat(sortDesc).map((v) => (v ? 'desc' : 'asc')) || null,
            page: page,
            limit: itemsPerPage === -1 ? null : itemsPerPage, // -1 is 'all' items
          });
        },
        'clients',
        true,
      );
    },

    async getCsrfToken() {
      this.csrfToken = await SecurityService.getCsrfToken();
    },

    async deleteClient(client) {
      const flash = new Flash('.flash--main');
      flash.clear();

      if (!confirm(`Delete the '${client.name}' client? This can't be undone.`)) {
        return;
      }

      try {
        await ClientService.deleteClient({ clientId: client.id, csrfToken: this.csrfToken });

        await this.queryClientsList();

        flash.flash('Client has been deleted.', 'success', true);
      } catch (err) {
        flash.flash('Could not delete the client. Please reload the page and try again.', 'error', true);

        console.error(err);
      }
    },

    goToPage(page) {
      this.options.page = parseInt(page, 10) || 1;
    },

    navigatePaginationKeyboardShortcuts(event) {
      if (event.altKey && event.key === 'ArrowLeft') {
        if (this.options.page != 1) {
          this.options.page = this.options.page - 1;
        }
      } else if (event.altKey && event.key === 'ArrowRight') {
        if (this.options.page < this.pageCount) {
          this.options.page = this.options.page + 1;
        }
      }
    },
  },
};
</script>

<style scoped>
.container {
  max-width: none !important;
}
</style>
